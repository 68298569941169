<template>
  <div v-bind="$attrs">
    <modal size="md-1" :show.sync="modal.show" data-class="programming-info">
      <template slot="header">
        <h5 class="modal-title">
          <img
            src="/img/icons/icons8/ios/chemical_plant_warning.png"
            width="25"
            class="mt-n2 mr-2"
          />
          <span>{{ modal.title }}</span>
        </h5>
      </template>
      <div class="pl-3">
        <div class="row">
          <div class="col-md-12 mb-3 info-section">
            <img
              src="/img/icons/icons8/ios/info-squared_warning.png"
              width="20"
              height="20"
              style="margin-right: 1.5%"
            /><Strong class="mb-3">Identificação</Strong>
            <div class="pzl-container-divider">
              <div></div>
            </div>
          </div>
          <div
            class="col-md-6"
            style="display: flex; flex-direction: column; gap: 8px"
          >
            <strong>{{ item.contract_proposal_code }}</strong>
            <span>{{ item.constructions_construction_name }} </span>
            <span>Obra - {{ item.constructions_construction_name }} </span>
          </div>
          <div class="col-md-12 mt-3 info-section">
            <img
              src="/img/icons/icons8/ios/truck_concrete_mixer.png"
              width="20"
              height="20"
              style="margin-right: 1.5%"
            /><Strong class="mb-3">Programação e viagem</Strong>
            <div class="pzl-container-divider">
              <div></div>
            </div>
          </div>
          <!-- <div
            class="col-md-12"
            style="display: flex; flex-direction: column; gap: 8px"
          >
            <strong class="mt-2">{{
              selected.schedule.contract_proposal.code
            }}</strong>
            <span>{{ selected.schedule.contract_proposal.customer_name }}</span>
            <span
              v-if="
                selected?.schedule?.addresses &&
                selected.schedule?.addresses.length
              "
              >Endereço: {{ selected.schedule.addresses[0].address }}</span
            >
            <span
              >Contato: {{ selected.schedule.responsible_receiver.name }} ({{
                selected.schedule.responsible_receiver.phone
                  ? selected.schedule.responsible_receiver.phone
                  : selected.schedule.responsible_receiver.email
              }})</span
            >
          </div> -->
          <!-- <div class="col-md-12 mt-3 info-section">
            <img
              src="/img/icons/chemical-plant.png"
              width="20"
              height="20"
              style="margin-right: 1.5%"
            /><Strong class="mb-3">Programação</Strong>
            <div class="pzl-container-divider">
              <div></div>
            </div>
          </div> -->
          <div class="col-md-12 mt-3">
            <div class="row mt-3">
              <div class="col-md-12"><strong>Programação total</strong></div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Data de início</div>
              <div
                class="col-md-4"
                style="border-bottom: 0.5px solid #e8e8e8"
              ></div>
              <div class="col-md-4">
                <div class="position-relative">
                  <span>{{ item.start }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Data de término</div>
              <div
                class="col-md-4"
                style="border-bottom: 0.5px solid #e8e8e8"
              ></div>
              <div class="col-md-4">
                <div class="position-relative">
                  <span>{{ item.end }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Ciclo médio de viagem</div>
              <div class="col-md-8">
                <div class="position-relative">
                  <!-- <textarea class="read-info" style="padding-left: 2%">{{
                    selected.observations ? selected.observations : "-"
                  }}</textarea> -->
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Ciclo médio de concreto</div>
              <div class="col-md-8">
                <div class="position-relative">
                  <!-- <textarea class="read-info" style="padding-left: 2%">{{
                    selected.observations ? selected.observations : "-"
                  }}</textarea> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-2 p-2">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="close-btn pointer" @click.prevent="modal.show = false">
            <i class="fas fa-times" style="font-size: 20px"></i>
            <span>Fechar</span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
export default {
  name: "ModalTravelInformation",
  inheritAttrs: false,
  components: {
    PuzlSelect,
    InputDateTimePicker,
  },
  data() {
    return {
      modal: {
        title: "Informação da programação",
        show: false,
      },
      item: null,
      loading: false,
    };
  },
  computed: {
    // ...mapGetters({
    //   $_users: "user/fetch",
    // }),
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @param {object} item
     * @param {string} item.contract_proposal_code
     * @param {string} item.constructions_construction_name
     * @param {string} item.mix_concreted_pieces_description
     * @param {Date} item.start
     * @param {Date} item.end
     * @returns {void}
     */
    openModal(item) {
      this.item = item;
      this.item.start = moment(item.start).format("DD/MM/YYYY H:mm");
      this.item.end = moment(item.end).format("DD/MM/YYYY H:mm");
      console.log(this.item);
      this.modal.show = true;
    },
    /**
     * @returns {void}
     */
    closeModal() {
      this.modal.show = false;
    },
    /**
     * @returns {void}
     */
    async init() {
      this.$Progress.start();
      let loader = this.$loading.show();
      // await this.getUsers();
      this.$Progress.finish();
      loader.hide();
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-info"] div.modal-header.pb-1 {
  background: #fef9f2 !important;
}

/deep/ div[data-class="programming-info"] div.modal-header.pb-1 > button i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

h5.modal-title > span,
h5.modal-title > small {
  font-family: "Fredoka";
  font-weight: 400;
  color: #2b2d32;
  font-size: 18px !important;
}

div.info-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 23px;
}

div.info-section > div.pzl-container-divider {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

div.info-section > div.pzl-container-divider > div {
  width: 100%;
  border: 0.5px solid #e8e8e8;
}

div.close-btn {
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  border: 0.5px solid #e8e8e8;
  gap: 10px;
}

div.close-btn > i {
  border: 1px solid #000;
  color: #000;
  padding: 3%;
  font-size: 8px !important;
}

div.close-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Fredoka";
}

.read-info {
  border: 0.5px solid #e8e8e8;
  height: 31px;
  padding: 8px 2px 8px 23px;
  border-radius: 4px;
  width: 100%;
}
textarea.read-info {
  height: 51px;
}
.container-icon {
  width: 25px;
  height: 31px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #db4539;
  border-radius: 8px;
  color: #db4539;
  padding: 4px 20px;
  height: 32px;
}
button.button-save {
  cursor: pointer;
  border: 0.5px solid #149e57;
  border-radius: 8px;
  color: #149e57;
  padding: 4px 30px;
  background-color: #fff;
  height: 32px !important;
  min-height: 32px !important;
}
.green-text-badge {
  color: #149e57;
}
.red-text-badge {
  color: #db4539;
}
.green-backgroud-light {
  background: #f2f7f3;
}
.red-backgroud-light {
  background: #fcf3f3;
}
label.app-badge {
  display: flex;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  cursor: pointer;
}
label.app-badge > input[type="checkbox"] {
  display: none;
}
label.app-badge > span {
  font-family: "Fredoka";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
div.alternative-add-btn {
  border: 0.5px solid #149e57;
  border-radius: 8px;
  width: 121px;
  gap: 4px;
  padding: 4px 12px 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  cursor: pointer;
}
div.alternative-add-btn > span {
  color: #149e57;
}
div.trash-input {
  position: absolute;
  height: 20px;
  width: 30px;
  cursor: pointer;
  z-index: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 25px;
}
</style>
