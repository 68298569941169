var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[(_vm.$_headers)?_c('div',{staticClass:"row card-wrapper d-flex mt-0 justify-content-end mx-1"},[_c('div',{staticClass:"col-md-4 px-md-2"},[_c('div',{staticClass:"card main-card mb-md-0"},[_c('div',{staticClass:"py-2 px-4"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('span',{staticClass:"new-default-black-font font-weight-500 fs-13"},[_vm._v(" "+_vm._s(_vm.$_headers.programmed_volume)+" m³ ")]),_c('span',{staticClass:"new-default-black-font font-weight-500 fs-13"},[_vm._v(" "+_vm._s(_vm.$_headers.programmed_travels)+" viagens ")])])])])])]),_c('div',{staticClass:"col-md-4 px-md-2"},[_c('div',{staticClass:"card main-card mb-md-0"},[_c('div',{staticClass:"py-2 px-4"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('span',{staticClass:"new-default-black-font font-weight-500 fs-13"},[_vm._v(" "+_vm._s(_vm.$_headers.performed_volume)+" m³ ")]),_c('span',{staticClass:"new-default-black-font font-weight-500 fs-13"},[_vm._v(" "+_vm._s(_vm.$_headers.performed_travels)+" viagens ")])])])])])]),_c('div',{staticClass:"col-md-4 px-md-2"},[_c('div',{staticClass:"card main-card mb-md-0"},[_c('div',{staticClass:"py-2 px-4"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('span',{staticClass:"new-default-black-font font-weight-500 fs-13"},[_vm._v(" "+_vm._s(_vm.$_headers.pending_volume)+" m³ ")]),_c('span',{staticClass:"new-default-black-font font-weight-500 fs-13"},[_vm._v(" "+_vm._s(_vm.$_headers.pending_travels)+" viagens ")])])])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/no-connection--v1_primary.png","width":"20px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-2"},[_c('h5',{staticClass:"col-12 new-default-gray-font font-weight-normal mb-0 fs-11"},[_vm._v("Programado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/no-connection--v1_success.png","width":"20px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-2"},[_c('h5',{staticClass:"col-12 new-default-gray-font font-weight-normal mb-0 fs-11"},[_vm._v("Realizado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/hourglass_warning.png","width":"20px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-2"},[_c('h5',{staticClass:"col-12 new-default-gray-font font-weight-normal mb-0 fs-11"},[_vm._v("A realizar")])])
}]

export { render, staticRenderFns }